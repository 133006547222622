






























import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import SendDoubtsLoading from '@/components/Doubts/SendDoubts/SendDoubtsLoading.vue';

@Component({
  components: {
    ExLoading,
    SendDoubtsLoading
  }
})
export default class NewDoubtLoading extends Vue {}
